<template>
  <div>
<div class="modal fade" id="createQuizModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" v-bind="$attrs" ref="createQuizModal">

  <div class="modal-dialog modal-xl" :class="[selectedType=='ai'?'modal-ai':'']">
      <div class="modal-content " id="createQuizModalContent" >


          <!-- Modal Content -->
          <div >

            <div class="" >
              <div class="container">
                <div class="d-flex  px-3 pt-3 d-flex justify-content-between align-items-center" >
                  <div :class="[selectedType==''?'invisible':'']" @click="backButton" class="d-flex" style="color:#73738D;

font-size: 14px;
cursor:pointer;
font-weight: 500;
">
                    <span><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.6667 9.16669V10.8334H6.66667L11.25 15.4167L10.0667 16.6L3.46667 10L10.0667 3.40002L11.25 4.58336L6.66667 9.16669H16.6667Z" fill="#73738D"/>
</svg>
</span>
Back
                  </div>
                     <div class="text-center" style="flex:1">
                          <p class="m-0" style="color:#292929;font-weight:600;font-size:22px;">{{ quizCategory }}</p>
                      </div>
                    <div>
                        <button
                        @click="CloseModal"
                        class="btn outline-none  outline-none border-0 "
                        style="cursor: pointer;color:#323232;"><i class="fa fa-times" aria-hidden="true"></i></button>

                    </div>
                </div>
                <div class="" >
                  <div class="d-flex justify-content-center align-items-center"  v-if="selectedType==''" >
                    <SelectCreateQuizType @click="chooseSelectedType($event)"></SelectCreateQuizType>
                  </div>
                 <ImportQuestionsAI @back="selectedType=''" v-else-if="selectedType=='import'"></ImportQuestionsAI>
                 <CreateQuizWithAI :stepArray="stepArray" @updateStep="updateStep" v-else-if="selectedType=='ai'"></CreateQuizWithAI>
              </div>
              </div>
            </div>
          </div>
</div>
      </div>
    </div>
</div>
 
</template>

<script>
import { mapGetters } from "vuex";
import $ from 'jquery';
import SelectCreateQuizType from './SelectCreateQuizType.vue';
import CreateQuizWithAI from './CreateQuizWithAI.vue';
import ImportQuestionsAI from './ImportQuestionsAI.vue';
// import Button from '../Layout/Button.vue';
export default {
    props:['title','subText'],

    components:  {
   SelectCreateQuizType,
   CreateQuizWithAI,
   ImportQuestionsAI
    },
    data(){
        return{
          selectedType:'',
          titleText:this.title,
          subTitleText:this.subText,
        stepArray:1
        }
    },
methods:{
  updateStep(value)
  {
    this.stepArray=value
  },
  backButton(){
    if(this.selectedType=='ai'&&this.stepArray>1)
    {
      this.stepArray -=1
     
    }
    else{
      this.stepArray=1;
      this.selectedType=''
    }
  },
  chooseSelectedType(type){
    if(type == 'templates'){
      this.CloseModal()
      this.$router.push(`/manage/create-quiz`)
    }
    else {
      this.selectedType = type
      this.stepArray = 1
    }
  },
open(){
  this.selectedType=''
     $("#createQuizModal").modal({
        backdrop: "static",
        keyboard: false,
        show: true,
      });
        // $('#createQuizModal').modal('show')
    },
    CloseModal(){
         $('#createQuizModal').modal('hide')
    },

    openUpgradePlan(){
        this.CloseModal()
        let webUsers = ['web','bc','wordpress']
           if( webUsers.includes(this.getUserType)){
            let url = window.location.origin + `/manage/account/pricing`
            window.open(url,"_self")
          // this.$router.push('/manage/account/pricing')
      }
      if(this.getUserType == 'shopify') {
        this.$emit("openPlansModalChild")
      } 
      if(this.getUserType == 'wix'){
         let url = `https://www.wix.com/apps/upgrade/f67f3bd5-3d73-486c-9bb2-618adcc19fb9`
      window.open(url,"_blank")
      }
       
    }


},
computed: {
    ...mapGetters(["GetTokenFromMetaTag","getNavbarData"]),
    quizCategory(){
      let title='Create a new Quiz'
    if(this.selectedType=='ai')
      title= 'Create with AI'
    else if(this.selectedType=='import') 
      title ='Import Questions'

      return title
    },
    getUserType() {
      return this.getNavbarData.userType
    }
  },
mounted() {
        // $(this.$refs.createQuizModal).on("show.bs.modal", this.getProductData)
    },
}
</script>

<style scoped>

@media (min-width: 1200px) {
    .modal-ai {
        max-width: 95%!important;
        transform: translate(60px, 0px)!important;
        margin:0!important;
    }
}
#createQuizModalContent{
    border-radius:20px;
}
.QuestionsDiv{
    height:100%;
}
.card-body{
    min-height: 200px!important;
}
.card{
    border-radius: 12px!important;
    text-align: center;
    border:none;
    box-shadow: 0px 4px 4px 0px #00000040;
    cursor: pointer;
}
.card:active{
    box-shadow: -0.0625rem 0 0.0625rem 0 rgba(26,26,26,.122) inset,0.0625rem 0 0.0625rem 0 rgba(26,26,26,.122) inset,0 0.125rem 0.0625rem 0 #1a1a1a33 inset!important;
}
.card-title{
    margin-top: 16px;
   font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
text-align: center;

}
.card-text{
   color: #aaaaab;
   font-size: 14px;
}
</style>
